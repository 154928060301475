/*
// import 'bootstrap/js/dist/alert';
// import 'bootstrap/js/dist/button';
// import 'bootstrap/js/dist/carousel';
// import 'bootstrap/js/dist/collapse';
// import 'bootstrap/js/dist/dropdown';
// import 'bootstrap/js/dist/modal';
// import 'bootstrap/js/dist/offcanvas';
// import 'bootstrap/js/dist/popover';
// import 'bootstrap/js/dist/scrollspy';
// import 'bootstrap/js/dist/tab';
// import 'bootstrap/js/dist/toast';
// import 'bootstrap/js/dist/tooltip';

// import * as bootstrap from 'bootstrap'
// import 'bootstrap/js/dist/modal'
// import 'bootstrap/js/dist/collapse'
// import 'bootstrap/js/dist/tab'

import Modal from 'bootstrap/js/dist/modal'
import Tab from 'bootstrap/js/dist/tab'
import Collapse from 'bootstrap/js/dist/collapse'
*/

import "bootstrap/js/dist/dropdown";
import "bootstrap/js/dist/offcanvas";
import "./components/back-to-top";
import "./components/header-menu-delay";
import "./components/chatbot";
import "./components/tarti-fancybox";
import "./components/tarti-video-loop";
import Modal from "bootstrap/js/dist/modal";
window.Modal = Modal;
import Collapse from "bootstrap/js/dist/collapse";
window.Collapse = Collapse;
import Toast from 'bootstrap/js/dist/toast';
window.Toast = Toast;
import Tooltip from "bootstrap/js/dist/tooltip";
window.Tooltip = Tooltip;
import "./components/data-added-redirect";